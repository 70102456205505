// import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
// import useGetPage from "../hooks/useGetPage";

import BoardList from "../../../BoardList/BoardList";
import ManagementList from "../../../ManagementList/ManagementList";
import NavBarItem from "../../../Navbar/NavBarItem";
import { baseUrl } from "../../../../services/Environment";

function Company({ lang, data }) {
  return (
    <>
      {/*----- navBar -----*/}
      <nav className="mt-3 mobile_m0 d-flex  py-3 gap-4 align-items-center xl-col justify-content-center">
        <div className="d-flex gap-3">
          <NavBarItem id={"Shareholding"}>
            {lang === "th"
              ? `โครงสร้างการถือหุ้นบริษัท`
              : `Shareholding Structure`}
          </NavBarItem>
          <NavBarItem id={"Management"}>
            {lang === "th" ? `โครงสร้างการจัดการ ` : `Management Structure`}
          </NavBarItem>
        </div>
        <div className="d-flex gap-3 ">
          <NavBarItem id={"Director"}>
            {lang === "th" ? `คณะกรรมการบริษัท` : `Board of Director`}
          </NavBarItem>
          <NavBarItem id={"BoardManagement"}>
            {lang === "th" ? `คณะผู้บริหาร` : ` Board of Managemen`}
          </NavBarItem>
        </div>
      </nav>
      {/*----- ข้อมูลบริษัท ------*/}
      <section style={{ paddingTop: "2.2vw" }}>
        <h1 className="container title-line fw-bold">
          {lang === "th" ? `ข้อมูลบริษัท` : `Corporate Information `}
        </h1>
        {/*------ โครงสร้างการถือหุ้นบริษัท -------*/}
        <div id="Shareholding">
          <div className="xl-invisible">
            <img
              src={
                lang === "th"
                  ? "/images/shareholding/นักลงทุนสัมพันธ์-3-05_0.jpg"
                  : "/images/shareholding/นักลงทุนสัมพันธ์-3 Eng-05_0.jpg"
              }
              className="w-100"
            />
          </div>
          <a
            href={
              lang === "th"
                ? "http://localhost:3000/images/shareholding/%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%A5%E0%B8%87%E0%B8%97%E0%B8%B8%E0%B8%99%E0%B8%AA%E0%B8%B1%E0%B8%A1%E0%B8%9E%E0%B8%B1%E0%B8%99%E0%B8%98%E0%B9%8C-3-40_0.jpg"
                : "http://localhost:3000/images/shareholding/%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%A5%E0%B8%87%E0%B8%97%E0%B8%B8%E0%B8%99%E0%B8%AA%E0%B8%B1%E0%B8%A1%E0%B8%9E%E0%B8%B1%E0%B8%99%E0%B8%98%E0%B9%8C-3%20Eng-40_0.jpg"
            }
            className="xl-visible"
            style={{ display: "none" }}
            target="_blank"
          >
            <img
              src={
                lang === "th"
                  ? "/images/shareholding/นักลงทุนสัมพันธ์-3-40_0.jpg"
                  : "/images/shareholding/นักลงทุนสัมพันธ์-3 Eng-40_0.jpg"
              }
              className="w-100"
            />
          </a>
        </div>
      </section>

      {/*------ โครงสร้างการจัดการ -------*/}
      <div id="Management">
        <div className="xl-invisible">
          <img
            src={
              lang === "th"
                ? "/images/shareholding/OrganizationChartTH_pc.jpg"
                : "/images/shareholding/OrganizationChartEng_pc.jpg"
            }
            className="w-100"
          />
        </div>
        <a
          href={
            lang === "th"
              ? "http://localhost:3000/images/shareholding/%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%A5%E0%B8%87%E0%B8%97%E0%B8%B8%E0%B8%99%E0%B8%AA%E0%B8%B1%E0%B8%A1%E0%B8%9E%E0%B8%B1%E0%B8%99%E0%B8%98%E0%B9%8C-2-42_0.jpg"
              : "http://localhost:3000/images/shareholding/Organization%20Chart%20Eng-42_0.jpg"
          }
          className="xl-visible"
          target="_blank"
          style={{ display: "none" }}
        >
          <img
            id="Management"
            src={
              lang === "th"
                ? "/images/shareholding/OrganizationChartTH_mobile.jpg"
                : "/images/shareholding/OrganizationChartEng_mobile.jpg"
            }
            className="w-100"
          />
        </a>
      </div>
      {/*------ สัดส่วนกรรมการบริษัท -------*/}
      <section
        id="Proportion"
        className="position-relative bg-Lgray w-100 section "
        style={{ minHeight: "500px" }}
      >
        <h1
          className="headerFont position-absolute text-white text-nowrap bg-blue headerPadding rounded-5 d-flex mx-auto"
          style={{
            top: "-3vw",
            left: "50%",
            transform: " translate(-50%, 0%)",
          }}
        >
          {lang === "th"
            ? "สัดส่วนกรรมการบริษัท"
            : "The Proportion of Directors"}
        </h1>
        <div className="row  mx-auto font w-75 mobile_w90">
          <div className="col-lg-6 d-flex mobile_col  mobile_p col-lg-6 section">
            <img
              src="/images/sustainable/นักลงทุนสัมพันธ์-สัดส่วน-12_0.png"
              className="logoImg4"
            />
            <div className="d-flex flex-column align-items-lg-start align-items-center  mt-lg-5 mt-3  ">
              <button className="text-white bg-blue-gradient px-3 py-2 rounded-pill mb-3">
                {lang === "th"
                  ? `กรรมการอิสระ 8 คน`
                  : `Independent Director 8 People `}
              </button>
              <ul className="m-0 ps-2">
                <li className="circle">
                  {lang === "th"
                    ? `กรรมการที่ไม่เป็นผู้บริหาร 4 คน `
                    : `Independent Director 4 People `}
                </li>
                <li className="circle">
                  {lang === "th"
                    ? `กรรมการที่เป็นผู้บริหาร 4 คน `
                    : `Executive Director 4 People `}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 d-flex mobile_col mobile_p col-lg-6 section">
            <img
              src="/images/sustainable/นักลงทุนสัมพันธ์-สัดส่วน-13_0.png"
              className="logoImg4"
            />
            <div className="d-flex flex-column align-items-lg-start align-items-center  mt-lg-5 mt-3 ">
              <button
                className="text-white bg-blue-gradient  py-2 rounded-pill mb-3"
                style={{ paddingInline: "90px" }}
              >
                {lang === "th" ? `เพศ ` : `Gender `}
              </button>
              <ul className="m-0 ps-2 d-flex flex-column gap-3">
                <li className="circle">
                  <img
                    src="/images/Logo/นักลงทุนสัมพันธ์-35_0.png"
                    className="ps-3"
                    style={{ height: "50px" }}
                  />{" "}
                  {lang === "th" ? `ชาย 5 คน ` : ` Male 5 People`}
                </li>
                <li className="circle ">
                  <img
                    src="/images/Logo/นักลงทุนสัมพันธ์-36_0.png"
                    className="ps-3"
                    style={{ height: "50px" }}
                  />{" "}
                  {lang === "th" ? `หญิง 3 คน ` : `Female  3 People`}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 d-flex mobile_col mobile_p col-lg-6 section">
            <img
              src="/images/sustainable/นักลงทุนสัมพันธ์-สัดส่วน-14_0.png"
              className="logoImg4"
            />
            <div className="d-flex flex-column align-items-lg-start align-items-center mt-lg-5 mt-3 ">
              <button className="text-white bg-blue-gradient px-5 py-2 rounded-pill mb-3">
                {lang === "th"
                  ? `อายุเฉลี่ย 50 ปี `
                  : `Average Age 50 Year Old `}
              </button>
              <ul className="m-0 ps-2">
                <li className="circle">
                  {lang === "th"
                    ? `อายุ 40-49 : 4 คน `
                    : `Age 40-49 : 4 People `}
                </li>
                <li className="circle">
                  {lang === "th"
                    ? `อายุ 50-59 : 3 คน `
                    : `Age 50-59 : 3 People `}
                </li>
                <li className="circle">
                  {lang === "th"
                    ? `อายุ 60-69 : 1 คน `
                    : `Age 60-69 : 1 People `}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 d-flex mobile_col mobile_p col-lg-6 section">
            <img
              src="/images/sustainable/นักลงทุนสัมพันธ์-สัดส่วน-15_0.png"
              className="logoImg4"
            />
            <div className="d-flex flex-column align-items-lg-start align-items-center mt-lg-5 mt-3 ">
              <button className="text-white bg-blue-gradient px-3 py-2 rounded-pill mb-3">
                {lang === "th"
                  ? `ระยะเวลาดำรงตำแหน่งเฉลี่ย 4 ปี`
                  : `Term Average or The Board of Director 4 Year`}
              </button>
              <ul className="m-0 ps-2">
                <li className="circle">
                  {lang === "th" ? `0-3 ปี : 4 คน ` : `0-3 Year : 4 People`}
                </li>
                <li className="circle">
                  {lang === "th" ? `4-6 ปี : 1 คน ` : `4-6 Year : 1 People `}
                </li>
                <li className="circle">
                  {lang === "th" ? `7-9 ปี : 1 คน ` : `7-9 Year : 1 People `}
                </li>
                <li className="circle">
                  {lang === "th" ? `> 9 ปี : 1 คน ` : `> 9  Year : 1 People`}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container font w-75">
          <p className=" text-brown mb-1">
            {lang === "th"
              ? ` หมายเหตุ : ไม่มีกรรมการอิสระที่ดำรงตำแหน่งเกินกว่า 9 ปี`
              : `Note: there's no any Independent Director holding in position for more than 9 years `}
          </p>
          <p>
            {lang === "th"
              ? `**กรรมการอิสระ หมายถึง
            กรรมการที่สามารถให้ความเห็นได้อย่างเป็นอิสระและเป็นไปตามหลักเกณฑ์ที่เกี่ยวข้อง
            มีคุณสมบัติครบถ้วนตามกฎหมายกำหนด ข้อมูล ณวันที่ 1 มิถุนายน 2566 `
              : `**Independent Director means a person who can give opinions independently and under the relevnt rules all the qualifications required by law. `}
          </p>
        </div>
      </section>
      {/*------ คณะกรรมการบริษัท -------*/}
      <section id="Director" className="container my-5">
        <div className="d-flex justify-content-center ">
          <h1 className=" text-white bg-blue headerPadding rounded-5 mt-4">
            {lang === "th" ? `คณะกรรมการบริษัท` : `Board of Director`}
          </h1>
        </div>
        <div className="my-4">
          {data?.attributes.board_of_directors.map((element) => {
            // console.log('element',element)
            return (
              <BoardList
                src={baseUrl + element.img.data.attributes.url}
                name={element.name}
                position={element.details}
              />
            );
          })}
        </div>
      </section>
      {/*------ คณะผู้บริหาร -------*/}
      <section
        id="BoardManagement"
        className="section"
        style={{
          backgroundImage:
            "url(/images/shareholding/นักลงทุนสัมพันธ์-ข้อมูลบริษัท-16.jpg)",
          backgroundRepeat: "noRepeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <h1 className=" text-center text-white">
            {lang === "th" ? `คณะผู้บริหาร` : `Board of Management`}
          </h1>
          <div className="d-flex justify-content-center">
            <div className="row">
              {data?.attributes.executive_committee.map((element) => {
                // console.log('element',element)
                return (
                  <ManagementList
                    src={baseUrl + element.img.data.attributes.url}
                    name={element.name}
                    position={element.details}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Company;
